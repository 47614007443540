@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
body {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  background-color: #222a35;
  /* background-image: url("../public/bground.gif"); */
  /* background-image: url("../public/background.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  color: whitesmoke;
}

* {
  font-family: 'Roboto', sans-serif;
  font-style: bold;
  /* color:#222a35 */
}

/* {
orangecolor : #d95540
background-color: #222a35;
font-color: #f7f7f9;
graycolor : #a6a6a6
ivory: FAF9F6
} */
